import { useContext, useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../../static/scss/home.scss";
import { useRecoilValue } from "recoil";

import CustomButton from "../../components/button/button";
import DragNDrop from "../../components/dragNDrop/DragNDrop";
import { useDragNDrop } from "../../components/dragNDrop/useDragNDrop";
import CustomTable from "../../components/table/customTable/CustomTable";
import useTable from "../../components/table/customTable/hook/useTable";
import { taskManagementContext } from "../../context/TaskManagementContext";
import { serviceUsableState } from "../../recoil/services.atom";
import { ReactComponent as IconTaskList } from "../../static/icons/home/tasklist.svg";
import { ReactComponent as IconFolder } from "../../static/icons/tasks/icon-folder.svg";

const DropZone = () => {
    const { inputRef, dragging } = useDragNDrop();
    const { t } = useTranslation();
    return (
        <div
            className={`d-lg-flex h-100 gap-5 flew-row justify-content-center justify-content-lg-between ${dragging ? "pe-none" : ""}`}
        >
            <div className="d-none d-lg-block home__dropzone__drag-preview">
                <div className="home__dropzone__drag-preview__drag-content h-100 w-100 d-flex align-items-center justify-content-center">
                    <h2 className="color-white fst-italic">{t("home.drop-zone.drop-your-file")}</h2>
                </div>
            </div>
            <div className="d-none home__dropzone__image-container gap-3 d-lg-flex flex-lg-column align-items-lg-center justify-content-lg-center">
                <div className="home__dropzone__image-container__img"></div>
                <span className="color-white fst-italic">{t("home.drop-zone.drag-here")}</span>
            </div>
            <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between align-items-start gap-2">
                <h2 className="home__dropzone__title">
                    {<Trans i18nKey={`home.drop-zone.title`} components={{ span: <span /> }} />}
                </h2>
                <span>{t("home.drop-zone.select-media")}</span>
                <span>{t("home.drop-zone.supported-files")}</span>
                <span className="mb-3 mb-lg-0">{t("home.drop-zone.size-limitation")}</span>
                <div className="w-100 d-flex justify-content-center justify-content-lg-end align-items-end gap-3">
                    <span className="d-none d-lg-block color-white fst-italic">
                        {t("home.drop-zone.select-from-device")}
                    </span>
                    <CustomButton
                        onClick={() => inputRef?.current?.click()}
                        style={{ position: "initial" }}
                        buttonText={t("home.drop-zone.select-file")}
                        customClass="btn-tasks"
                        icon={<IconFolder fill="white" width={16} />}
                    />
                </div>
            </div>
        </div>
    );
};

const MediumTaskList = () => {
    const { t } = useTranslation();
    const canUseTask = useRecoilValue(serviceUsableState);
    const [tableLoading, setTableLoading] = useState(true);
    const { initTable, body } = useTable();
    const taskManagerStore = useContext(taskManagementContext);
    const { push } = useHistory();

    useEffect(() => {
        setTableLoading(taskManagerStore.isLoading);
        if (!taskManagerStore.isLoading && taskManagerStore.historyCallsTable) {
            const data = { ...taskManagerStore.historyCallsTable };
            if (data && data.body) {
                let tempHead = [...data.head];
                tempHead = tempHead.map((h) => ({ ...h, title: "" }));
                initTable({ ...data, body: data.body.splice(0, 5), head: tempHead });
            }
        }
    }, [taskManagerStore]);

    return (
        <div className="home__tasks-list d-flex flex-column">
            <h2 className="color-secondary">{t("home.tasks-list.title")}</h2>
            <CustomTable
                customClassname={canUseTask.status === false ? "table-disabled no-head" : "no-head"}
                loading={tableLoading}
            />
            {body && body.length > 0 && (
                <div className="d-flex justify-content-end">
                    <CustomButton
                        onClick={() => push("/task-manager")}
                        style={{ position: "initial" }}
                        buttonText={t("home.tasks-list.go-to-task-list")}
                        // classNameType="main"
                        customClass="btn-main task-list"
                        icon={<IconTaskList fill="white" width={16} />}
                    />
                </div>
            )}
        </div>
    );
};

export const Home = () => {
    const history = useHistory();
    const [dragEnter, setDragEnter] = useState(false);
    const canUseTask = useRecoilValue(serviceUsableState);
    const dropzoneDisabled = !canUseTask.status;

    const handleDragEnter = (v: boolean) => {
        setDragEnter(v);
    };

    return (
        <div className="d-flex flex-column home gap-4">
            <DragNDrop
                disabled={dropzoneDisabled}
                onDragEnter={() => handleDragEnter(true)}
                onDragLeave={() => handleDragEnter(false)}
                onDrop={() => history.push("/task-manager/anonymization")}
            >
                <div
                    className={`home__dropzone ${dragEnter ? "home__dropzone--dragging" : ""} ${dropzoneDisabled ? "home__dropzone--disabled" : ""}`}
                >
                    <DropZone />
                </div>
            </DragNDrop>
            <div className="middle-container flex-grow-1">
                <MediumTaskList />
            </div>
        </div>
    );
};
