import React, { useEffect, useRef } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { atom, useRecoilValue } from "recoil";

import { PreviewTask } from "./Preview";
import CustomButton from "../../../../../components/button/button";
import { Checkbox } from "../../../../../components/checkbox/checkbox";
import { useDragNDrop } from "../../../../../components/dragNDrop/useDragNDrop";
import { Radio } from "../../../../../components/radio/radio";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import useWindowDimensions from "../../../../../hook/useWindowDimensions";
import { ReactComponent as IconAnonymization } from "../../../../../static/icons/tasks/icon-anonymization.svg";
import { ReactComponent as IconCheck } from "../../../../../static/icons/tasks/icon-check.svg";
import { ReactComponent as FaceInactive } from "../../../../../static/icons/tasks/icon_face.svg";
import { ReactComponent as FaceActive } from "../../../../../static/icons/tasks/icon_face_active.svg";
import { ReactComponent as PlatesInactive } from "../../../../../static/icons/tasks/icon_plates.svg";
import { ReactComponent as PlatesGreen } from "../../../../../static/icons/tasks/icon_plates_active.svg";
import { FormAskAnonymization, BlurOptionsKey } from "../../../../../types";

const ActionButtons: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const formContext = useFormikContext<FormAskAnonymization>();
    const { dirty, errors } = formContext;
    // const canSubmit = dirty && Object.keys(errors).length === 0;
    const canSubmit = Object.keys(errors).length === 0;
    const onSubmit = () => {
        onConfirmTask(formContext.values);
    };
    return (
        <div className="d-flex justify-content-center py-2">
            <CustomButton
                classNameType="cancel"
                buttonText="Cancel"
                customClass="mr-1"
                onClick={onCancelTask}
            />
            <CustomButton
                classNameType="secondary"
                buttonText="Create"
                disabled={!canSubmit}
                onClick={onSubmit}
                icon={<IconCheck width={13} />}
            />
        </div>
    );
};

export type BlurOptionsStep = Record<
    "select-file" | "blur-settings",
    {
        state: "inactive" | "active";
    }
>;

export const defaultOptionsSettingsState: BlurOptionsStep = {
    "select-file": {
        state: "active"
    },
    "blur-settings": {
        state: "inactive"
    }
};

export const optionsSettingsState = atom<BlurOptionsStep>({
    key: "optionsSettings",
    default: defaultOptionsSettingsState
});

const OptionRowCases = ["anonymization-mode", "blur-options"] as const;
type OptionRowCase = (typeof OptionRowCases)[number];

type OptionRowExtractProps<T extends OptionRowCase> = {
    value: BlurOptionsKey;
    type: T extends "anonymization-mode" ? "radio" : "checkbox";
};
type OptionRowProps<T extends OptionRowCase> = {
    icons: {
        active: () => JSX.Element;
        inactive: () => JSX.Element;
    };
    activated: boolean;
    text: string;
    onClickToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    tooltip?: string;
    disabled?: boolean;
    customClass?: string;
} & OptionRowExtractProps<T>;

const OptionRow = <T extends OptionRowCase>({
    icons,
    activated,
    text,
    value,
    onClickToggle,
    type,
    isChecked,
    tooltip,
    disabled,
    customClass
}: OptionRowProps<T>) => {
    const { width } = useWindowDimensions();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { active: LogoActive, inactive: LogoInactive } = icons;
    const Logo = activated && isChecked ? LogoActive : LogoInactive;

    const handleMainDivClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (inputRef.current) {
            inputRef.current.click(); // Programmatically trigger a click event on the input element
        }
    };

    const getClassName = (
        activated: boolean,
        isDisabled: boolean | undefined,
        isChecked: boolean | undefined,
        customClass?: string
    ) => {
        return `filter-container__step__blur-settings__option-row d-flex justify-content-between ${activated ? "filter-container__step__blur-settings__option-row--activated opacity-100" : "opacity-50"} ${isChecked ? "filter-container__step__blur-settings__option-row--checked" : ""} ${isDisabled ? "filter-container__step__blur-settings__option-row--disabled" : ""} ${customClass || ""}`.trim();
    };

    return (
        <div style={{ cursor: disabled ? "not-allowed" : "initial" }}>
            <div
                onClick={handleMainDivClick}
                className={getClassName(activated, disabled, isChecked, customClass)}
            >
                <div className="w-100 d-flex ml-2 ml-lg-0 mt-2 mt-lg-0 flex-column flex-lg-row align-items-center justify-content-between">
                    <div className={`filter-container__step__blur-settings__option-row__logo`}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <Logo />
                        </div>
                    </div>
                    <div className="ml-2 w-100 d-flex align-items-center h-100 w-100 filter-container__step__blur-settings__option-row__text duration-200 ease-in-out">
                        <span className="color-clear-blue-grey">{text}</span>
                        {tooltip && width > 992 && (
                            <Tooltip content={tooltip} disabled={!activated}>
                                <sup>
                                    <div className="filter-container__step__blur-settings__option-row__information">
                                        <small>i</small>
                                    </div>
                                </sup>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="w-25 h-100 filter-container__step__blur-settings__option-row__checkbox">
                    {type === "checkbox" ? (
                        <Checkbox
                            value={value}
                            isDisabled={!activated}
                            onClick={onClickToggle}
                            customClass="my-0"
                            isChecked={isChecked}
                            inputRef={inputRef}
                        />
                    ) : (
                        <Radio
                            value={value}
                            isDisabled={!activated}
                            onClick={onClickToggle}
                            isChecked={isChecked}
                            inputRef={inputRef}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export interface BlurOptionsProps {
    onConfirmTask: (data: FormAskAnonymization) => void;
    onCancelTask: () => void;
}

export const BlurSettings: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const state = useRecoilValue(optionsSettingsState);
    const stepState = state["blur-settings"].state;
    const isActive = stepState === "active";
    const formikContext = useFormikContext<FormAskAnonymization>();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const linkedOptions: BlurOptionsKey[] = ["activation_faces_blur", "activation_plates_blur"];

    const getCheckedLinkedOptions = () => {
        return linkedOptions.filter(
            (key) => formikContext.values[key as BlurOptionsKey]
        ) as BlurOptionsKey[];
    };

    const handleClickToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const value = e.target.value as BlurOptionsKey;
        formikContext.setValues((prev) => ({ ...prev, [value]: !prev[value] }));
    };

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>, value: BlurOptionsKey) => {
        const isChecked = e.target.checked;

        if (linkedOptions.includes(value)) {
            const checkedLinkedOptions = getCheckedLinkedOptions();

            if (!isChecked && checkedLinkedOptions.length <= 1) {
                return;
            }
        }

        formikContext.setFieldValue(value, isChecked);
    };

    return (
        <div className={`filters-container__step`}>
            <h2 className="filters-container__step-title">
                <IconAnonymization width={35} />
                {t("task-management.tasks.anonymization.settings")}
            </h2>
            <div className="filter-container__step__blur-settings">
                <h3 className="filter-container__step__blur-settings-title">
                    {t("task-management.tasks.anonymization.detection")}
                </h3>
                <div className="row">
                    <div className="col-6 mb-3 mb-lg-0">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <FaceActive width={50} />,
                                inactive: () => <FaceInactive width={50} />
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.face-responsive")
                                    : t("task-management.tasks.anonymization.faces")
                            }
                            value={"activation_faces_blur"}
                            onClickToggle={(e) => handleToggle(e, "activation_faces_blur")}
                            type="checkbox"
                            isChecked={formikContext.values.activation_faces_blur}
                        />
                    </div>
                    <div className="col-6 mb-3 mb-lg-0">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <PlatesGreen width={50} />,
                                inactive: () => <PlatesInactive width={50} />
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.plates-responsive")
                                    : t("task-management.tasks.anonymization.plates")
                            }
                            value={"activation_plates_blur"}
                            onClickToggle={(e) => handleToggle(e, "activation_plates_blur")}
                            type="checkbox"
                            isChecked={formikContext.values.activation_plates_blur}
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <></>,
                                inactive: () => <></>
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.json-responsive")
                                    : t("task-management.tasks.anonymization.json")
                            }
                            value={"output_detections_url"}
                            onClickToggle={handleClickToggle}
                            type="checkbox"
                            isChecked={formikContext.values.output_detections_url}
                            tooltip={t("task-management.tasks.anonymization.json-information")}
                            customClass="no-bg no-border checkbox-first"
                        />
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-3">
                <p className="col-12 offset-md-2 laius text-center m-0 color-clear-blue-grey">
                    {t("task-management.tasks.image-processing")}
                </p>
            </div>
            <ActionButtons onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
        </div>
    );
};

const InputOption: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const { setValues, setFieldTouched } = useFormikContext<FormAskAnonymization>();
    const { file } = useDragNDrop();

    useEffect(() => {
        setValues((prev) => ({
            ...prev,
            anonymization_mode: "auto",
            output_detections_url: false
        }));
        setFieldTouched("input_media", true);
    }, [file]);

    return <PreviewTask onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />;
};

export const BlurOptions: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    return (
        <div className="filters-container">
            <InputOption onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
        </div>
    );
};
